import React, { useContext, useEffect, useState } from "react";
import first_banner from "../../assets/landpage/hive_banner.png";
import hat from "../../assets/landpage/captain hat-min.png";
// import hatgif from "../../assets/landpage/captain Hat.gif";
import fortune from "../../assets/landpage/investment journey image.png";
import people from "../../assets/landpage/edited 1.png";
// import photo1 from "../../assets/landpage/photo-1.svg";
// import photo2 from "../../assets/landpage/photo-2.svg";
// import photo3 from "../../assets/landpage/photo-3.svg";
import frame from "../../assets/landpage/frame.svg";
import hive1 from "../../assets/landpage/hive1.svg";
import hive2 from "../../assets/landpage/hive2.svg";
import table1 from "../../assets/landpage/table.svg";
import table2 from "../../assets/landpage/table2.svg";

import hivelogo from "../../assets/Homepage/hive logo 2.png";

import {
  Box,
  Button,
  Typography,
  Grid,
  Stack,
  useMediaQuery,
} from "@mui/material";
// import { Link } from 'react-router-dom';
import LandHeader from "../LandingHeader/LandHeader";
import { getAllAffiliateUser } from "../../services/api";
import { useTheme } from "@emotion/react";
import ContactUs from "../ContactUs/ContactUs";
import { Link } from "react-router-dom";
import "../CaptainBees/CaptainBees.css";
import HivePack from "./HivePack";
import VideoSlider from "./VideoSlider";

const First = () => {
  // const [captainbees, setCaptainBees] = useState();
  const [topRatedCbees, setTopRatedCBees] = useState();
  const [themes, setTheme] = useState(
    localStorage.getItem("selectedTheme") || "light"
  );
  useEffect(() => {
    window.scrollTo(0, 0);
    const handleStorageChange = (event) => {
      console.log(event);
      setTheme(event.currentTarget.localStorage.selectedTheme);
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);
  useEffect(() => {
    getAllAffiliateUser().then((data) => {
      // Define the names of the users you want to filter
      const targetNames = [
        "Queen Bee",
        "Grandma Honey",
        "Golden Sea Sailing",
        "Blockmichael22",
      ];

      // Map over targetNames to preserve the order and find corresponding users
      const orderedFilteredUsers = targetNames
        .map((name) => data.find((user) => user.accname === name))
        .filter((user) => user !== undefined); // Filter out undefined entries (if any user is not found)

      // Set the ordered filtered users to state
      setTopRatedCBees(orderedFilteredUsers);
    });
  }, []);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  // console.log(isMobile);

  return (
    <>
      <LandHeader />
      <Box mt={30} justifyContent={"center"}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            mt: 5,
          }}
        >
          <Box
            component="img"
            sx={{
              width: `${isMobile ? "100%" : "54%"}`,
            }}
            alt=""
            src={first_banner}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              margin: "auto",
              backgroundColor: "#FFD000",
              color: "#282828",
            }}
          >
            <Typography
              variant="text"
              fontSize={isMobile ? "20px" : "49px"}
              fontWeight={700}
              mt={3}
            >
              Simplify Your Investments
            </Typography>
            <Typography
              variant="text"
              fontSize={isMobile ? "11px" : "27px"}
              fontWeight={500}
              textAlign={"left"}
              mb={4}
              mt={1}
            >
              Let Professionals Guide Your Financial Decisions
            </Typography>
          </Box>

          {isMobile && (
            <Button
              variant="contained"
              disableTouchRipple
              sx={{
                backgroundColor: "#FFB300",
                borderRadius: "2px",
                color: "#282828",
                //   width:"30%",
                px: 5,
                py: 1,
                textTransform: "none",
                fontSize: "13px",
                fontWeight: 600,
                boxShadow: "none",
                mt: 3,
                "&:hover": {
                  backgroundColor: "#FFD000",
                  boxShadow: "none",
                },
              }}
            >
              Captain Bee Login
            </Button>
          )}
        </Box>

        <h1
          style={{
            margin: "70px 0px 20px 0px",
            fontSize: "62px",
            fontWeight: "bold",
          }}
        >
          Happy Family
        </h1>
        <p
          style={{
            margin: "0px 0px 70px 0px",
            fontSize: "16px",
          }}
        >
          From Indexx Investors
        </p>
        <VideoSlider />
        <Box
          // width={"55%"}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            // ml: isMobile ? 0 : 3,
            width: `${isMobile ? "100%" : "100%"}`,
            marginTop: "130px",
          }}
        >
          <Typography
            variant="text"
            fontSize={isMobile ? "15px" : "20px"}
            fontWeight={400}
          >
            What is
          </Typography>

          <Typography
            variant="text"
            fontSize={isMobile ? "40px" : "80px"}
            fontWeight={700}
            marginTop="-20px"
          >
            <img
              style={{
                width: "12%",
              }}
              alt=""
              src={hivelogo}
            />{" "}
            Hive
          </Typography>
          <Typography
            variant="text"
            fontSize={isMobile ? "12px" : "13px"}
            fontWeight={400}
            textAlign={"center"}
            mt={2}
            maxWidth={500}
            mb={2}
          >
            Welcome to the Hive, Where Financial Innovation Meets Community
            Collaboration. Our dynamic ecosystem is tailored for individuals
            navigating the complexities of cryptocurrency and financial markets.
            At its core, the Hive is a platform driven by collective expertise,
            offering members access to invaluable resources, expert guidance,
            and curated investment opportunities. Through our Hive Packs,
            members discover simplified strategies and educational materials to
            elevate their investment journey. With Captain Bees and Honey Bees
            at the helm, our community thrives on collective growth and
            knowledge-sharing, ensuring everyone's success in the rapidly
            evolving landscape of finance.{" "}
          </Typography>
        </Box>

        <Box
          // width={"55%"}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            // ml: isMobile ? 0 : 3,
            width: `${isMobile ? "100%" : "100%"}`,
            marginTop: "130px",
          }}
        >
          <Typography
            variant="text"
            fontSize={isMobile ? "15px" : "20px"}
            fontWeight={400}
          >
            Indexx Hive
          </Typography>

          <Typography
            variant="text"
            fontSize={isMobile ? "40px" : "80px"}
            fontWeight={700}
            marginTop="-20px"
          >
            Membership
          </Typography>
          <Typography
            variant="text"
            fontSize={isMobile ? "12px" : "13px"}
            fontWeight={400}
            textAlign={"center"}
            mt={2}
            maxWidth={500}
            mb={2}
          >
            Unlock exclusive benefits and opportunities in decentralized finance
            (DeFi) with Indexx Hive Membership. Gain access to advanced trading
            tools, educational resources, priority support, and rewarding
            opportunities. Join us today and elevate your crypto journey
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "50px",
          }}
        >
          <Box
            sx={{
              marginBottom: isMobile ? "20px" : 0,
            }}
          >
            <img
              style={{
                width: isMobile ? "100%" : "500px",
              }}
              alt=""
              src={hive1}
            />{" "}
            <br />
            <Button
              variant="contained"
              disableTouchRipple
              sx={{
                backgroundColor: "#FFB300",
                borderRadius: "2px",
                color: "#282828",
                width: "280px",
                px: 3,
                py: 1,
                textTransform: "none",
                fontSize: isMobile ? "9px" : "13px",
                fontWeight: 600,
                boxShadow: "none",
                mt: 3,
                "&:hover": {
                  backgroundColor: "#FFD000",
                  boxShadow: "none",
                  color: "#282828",
                },
              }}
              href="/bees"
            >
              Buy Captain Bee Membership
            </Button>
          </Box>
          <Box
            sx={{
              marginLeft: isMobile ? 0 : "120px",
            }}
          >
            <img
              style={{
                width: isMobile ? "100%" : "500px",
              }}
              alt=""
              src={hive2}
            />
            <br />
            <Button
              variant="contained"
              disableTouchRipple
              sx={{
                backgroundColor: "#8EDF78",
                borderRadius: "2px",
                color: "#282828",
                width: "280px",
                px: 3,
                py: 1,
                textTransform: "none",
                fontSize: isMobile ? "9px" : "13px",
                fontWeight: 600,
                boxShadow: "none",
                mt: 3,
                "&:hover": {
                  backgroundColor: "#45C921", // Keep the same background color on hover
                  color: "#282828", // Keep the same text color on hover
                  boxShadow: "none",
                },
              }}
              href="/honey-bees"
            >
              Buy Honey Bee Membership
            </Button>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            marginTop: "100px",
          }}
        >
          <img
            style={{
              width: "70%",
            }}
            alt=""
            src={themes === "light" ? table1 : table2}
          />
        </Box>
        <Box>
          <Button
            disableTouchRipple
            sx={{
              color: "#FFB300",
              px: 0,
              textTransform: "none",
              fontSize: "13px",
              mt: 6,
              mb: 5,
              "&:hover": {
                backgroundColor: "transparent",
              },
            }}
          >
            <a
              href="/bees"
              style={{
                textDecoration: "none", // Set textDecoration to "none" here
                color: "#FFB300", // Add the color style for the anchor tag
              }}
              className="hive-link"
            >
              See more captain bees
            </a>
          </Button>
        </Box>

        {/* <Box
          sx={{
            width: "100%",
            maxWidth: "1100px",
            margin: "100px auto",
            padding: "0px 50px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              textAlign: "left",
              justifyContent: isMobile ? "center" : "space-between",
              alignItems: "center",
            }}
          >
            <div style={{ order: isMobile ? 2 : 0 }}>
              {" "}
              <Typography
                variant="text"
                fontSize={isMobile ? "15px" : "20px"}
                fontWeight={400}
                mb={"-20px"}
              >
                Welcome to
              </Typography>
              <br />
              <Typography
                variant="text"
                fontSize={isMobile ? "40px" : "80px"}
                fontWeight={700}
              >
                Indexx Hive
              </Typography>
              <Typography
                fontSize={isMobile ? "11px" : "13px"}
                fontFamily={"Inter"}
                fontWeight={400}
                lineHeight={"28px"}
                textAlign={"left"}
              >
                The Hive concept introduces a dynamic ecosystem within
                Indexx.ai, resembling a bustling beehive teeming with diverse
                activity. Designed to empower and benefit regular users—referred
                to as HoneyBees—the Hive concept creates a collaborative
                environment that simplifies and enhances their experience in the
                world of cryptocurrency investments and trading.
              </Typography>
            </div>
            <img
              style={{
                width: "50%",
              }}
              alt=""
              src={hivelogo}
            />
          </Box>
        </Box> */}

        <Box
          sx={{
            display: "flex",
            flexDirection: `${isMobile ? "column" : "row"}`,
            justifyContent: "center",
            alignItems: "center",
            width: "100%",

            maxWidth: "1100px",
            margin: "100px auto",
            padding: "0px 50px",
          }}
        >
          {/* <Box sx={{
                    backgroundImage: { hatgif },
                    width: "35%",
                }}> */}

          <div
            style={{ flex: 1, justifyContent: "flex-start", display: "flex" }}
          >
            <img
              style={{
                width: "80%",
                marginRight: "auto",
              }}
              alt=""
              src={hat}
            />
          </div>

          {/* </Box> */}

          <Box
            sx={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <Typography
              variant="text"
              fontSize={isMobile ? "15px" : "20px"}
              fontWeight={400}
              textAlign={"left"}
              mb={isMobile ? 0 : "-20px"}
            >
              Why choose a
            </Typography>
            <Typography
              variant="text"
              fontSize={isMobile ? "40px" : "80px"}
              fontWeight={700}
              textAlign={"left"}
              ml={"-4px"}
            >
              Captain Bee
            </Typography>

            <Typography
              fontSize={isMobile ? "11px" : "13px"}
              fontFamily={"Inter"}
              fontWeight={400}
              lineHeight={"28px"}
              textAlign={"left"}
              // width={"92%"}
              width={isMobile ? "auto" : "447.81px"}
            >
              By choosing a Captain Bee, you gain a dedicated partner who is
              committed to nurturing your financial growth, ensuring that you
              not only embark on a journey of financial exploration but also
              stand to reap the rewards of well-informed decisions. In the vast
              realm of possibilities within the Indexx Hive, a Captain Bee is
              the compass that directs you toward your desired destination of
              investment success.
            </Typography>

            <Button
              variant="contained"
              disableTouchRipple
              sx={{
                backgroundColor: "#FFB300",
                borderRadius: "2px",
                color: "#282828",
                width: `${isMobile ? "100%" : "280px"}`,
                px: 3,
                py: 1,
                textTransform: "none",
                fontSize: `${isMobile ? "9px" : "13px"}`,
                fontWeight: 600,
                boxShadow: "none",
                mt: 3,
                "&:hover": {
                  backgroundColor: "#FFD000",
                  boxShadow: "none",
                },
              }}
            >
              <a
                href="/bees"
                style={{
                  textDecoration: "none", // Set textDecoration to "none" here
                  color: "#282828", // Add the color style for the anchor tag
                }}
              >
                Select your Captain Bee now!
              </a>
            </Button>
          </Box>
        </Box>

        {/* Hive Pack here */}
        <HivePack />

        {/* <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            mb: 10,
            mt: 28,
            fontSize: `${isMobile ? "28px" : "50px"}`,
            fontWeight: 600,
          }}
        >
          Trending Captain Bees
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              // gap: 2
            }}
          >
            <Grid
              container
              // columns={{ xs: 1, sm: 12, md: 12 }}
              spacing={{ xs: 10, md: 2 }}
              sx={{ maxWidth: "880px" }}
            >
              {topRatedCbees?.map((item) => (
                <Grid item xs={12} sm={12} md={3}>
                  <>
                    <Link
                      to={`/captainbee/${item.Username}`}
                      style={{
                        textDecoration: "none",
                        color: "var(--body_color)",
                      }}
                    >
                      <Stack
                        direction="column"
                        justifyContent={"center"}
                        alignItems={"center"}
                        width={"auto"}
                      >
                        <Box
                          sx={{
                            width: "216px",
                            height: "215px",
                            backgroundImage: `url(${frame})`,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "center",
                            position: "relative",
                            cursor: "pointer",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            alignSelf: "center",
                            // border:"none"
                          }}
                        >
                          {item?.photoIdFileurl === null ||
                          item?.photoIdFileurl === undefined ? null : (
                            <Box className="main-hexagon">
                              <img
                                alt=""
                                src={item?.photoIdFileurl}
                                // src={(item?.photoIdFileurl === undefined || item?.photoIdFileurl === null) ? frame : item?.photoIdFileurl}
                                width={"63px"}
                                height={"66px"}
                                ml={"-6px"}
                                border={"none"}
                              />
                            </Box>
                          )}
                        </Box>

                        <Typography
                          variant="text"
                          fontSize={isMobile ? "15px" : "20px"}
                          fontWeight={700}
                          mt={2}
                        >
                          {item?.accname}
                        </Typography>
                        <Typography
                          variant="text"
                          fontSize={isMobile ? "11px" : "13px"}
                          fontWeight={400}
                          mt={1}
                          mb={3}
                        >
                          {item?.firstname + " " + item?.lastname}
                        </Typography>
                      </Stack>
                    </Link>
                  </>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>
        {/* <Box
          sx={{
            display: "flex",
            flexDirection: `${isMobile ? "column" : "row"}`,
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            maxWidth: "1258px",
            margin: "auto",
            padding: "20px",
            mx: "auto",
            my: 35,
          }}
        >
          <div style={{ flex: 1.5, padding: "5px" }}>
            <img
              style={{
                width: "100%",
              }}
              alt=""
              src={fortune}
            />
          </div>

          <Box
            // width={"55%"}
            sx={{
              display: "flex",

              flexDirection: "column",
              flex: 2,
              alignItems: "flex-start",
            }}
          >
            <Typography
              variant="text"
              fontSize={isMobile ? "15px" : "20px"}
              fontWeight={400}
              textAlign={"left"}
            >
              Empower Your
            </Typography>
            <Typography
              variant="text"
              fontSize={isMobile ? "24px" : "72px"}
              fontWeight={700}
              textAlign={"left"}
            >
              Investment Journey
            </Typography>

            <Typography
              fontSize={isMobile ? "11px" : "13px"}
              fontFamily={"Inter"}
              fontWeight={400}
              lineHeight={"28px"}
              textAlign={"left"}
              // width={"92%"}
            >
              A HoneyBee should embrace the Indexx Hive as it opens the gateway
              to a transformative investment journey. Within this dynamic
              ecosystem, you gain access to a world of expert guidance,
              tailor-made strategies, and collaborative growth opportunities.
              The Indexx Hive isn't just a platform; it's a community where
              HoneyBees and Captain Bees work in harmony, ensuring your
              investment success becomes a shared achievement. With cutting-edge
              tools like the Honey Jar for transparent monitoring and the Nectar
              staking platform for stable returns, the Indexx Hive empowers you
              to navigate the complexities of cryptocurrency investments with
              confidence. By choosing the Indexx Hive, you're not just entering
              a new realm of possibilities; you're embarking on a path to
              financial growth under the mentorship of seasoned leaders and
              within an ecosystem designed for your prosperity.
            </Typography>
          </Box>
        </Box> */}

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            mt: 35,
            // maxWidth:"1520px"
          }}
        >
          <Box
            component="img"
            sx={{
              width: "100%",
              mb: 4,
              maxWidth: "1520px",
            }}
            alt=""
            src={people}
          />
          <Box
            // width={"55%"}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              // ml: isMobile ? 0 : 3,
              width: `${isMobile ? "60%" : "100%"}`,
            }}
          >
            <Typography
              variant="text"
              fontSize={isMobile ? "14.5px" : "50px"}
              fontWeight={500}
              textAlign={"left"}
            >
              Elevate Your Investment Experience
            </Typography>
            <Typography
              variant="text"
              fontSize={isMobile ? "12px" : "13px"}
              fontWeight={400}
              textAlign={"left"}
              mt={2}
              mb={2}
            >
              Join now to handpick your Captain Bee and begin a seamless journey
              to financial success.
            </Typography>

            <Button
              variant="contained"
              disableTouchRipple
              sx={{
                backgroundColor: "#FFB300",
                borderRadius: "2px",
                color: "#282828",
                // px:`${isMobile ? 6.1 : 10}`,
                px: isMobile ? "auto" : 10,
                width: `${isMobile ? "100%" : "auto"}`,
                py: 1,
                textTransform: "none",
                fontSize: `${isMobile ? "9px" : "13px"}`,
                fontWeight: "700",
                boxShadow: "none",
                mt: 3,
                "&:hover": {
                  backgroundColor: "#FFD000",
                  boxShadow: "none",
                },
              }}
            >
              <a
                href="/bees"
                style={{
                  textDecoration: "none", // Set textDecoration to "none" here
                  color: "#282828", // Add the color style for the anchor tag
                }}
              >
                Select your Captain Bee now!
              </a>
            </Button>
          </Box>
        </Box>
        <ContactUs />
      </Box>
    </>
  );
};

export default First;
