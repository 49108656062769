import React, {useState, useEffect} from 'react'
import jelly from "../../assets/subnav/royal jelly icon 1.svg";
import nectar from "../../assets/subnav/nectar 2 1.svg";
import nuggets from "../../assets/subnav/nuggets 1.svg";
import waggle from "../../assets/subnav/wiggle icon 1.svg";

import jelly_dark from "../../assets/subnav/darkmode/royal jelly icon 2.svg";
import nectar_dark from "../../assets/subnav/darkmode/nectar 2 2.svg";
import nuggets_dark from "../../assets/subnav/darkmode/nuggets 3.svg";
import waggle_dark from "../../assets/subnav/darkmode/waggle icon white.svg";
import { useMediaQuery } from '@mui/material'

import './JarHeader.css'
// import { Link } from 'react-router-dom';
import { useTheme } from '@emotion/react';

const JarHeader = () => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const [seltheme, setSeltheme] = useState(
        localStorage.getItem('selectedTheme') || "dark"
      );
    
      useEffect(() => {
        const handleStorageChange = (event) => {
          console.log(event);
          setSeltheme(event.currentTarget.localStorage.selectedTheme);
        };
    
        window.addEventListener('storage', handleStorageChange);
        
        return () => {
          window.removeEventListener('storage', handleStorageChange);
        };
      }, []);

    return (
        <div style={{position:"fixed", 
        
        top:`${isMobile ? "192px" : "173px"}`, 
        width:"100%", zIndex:999, background:"var(--main-body)"}}>

        <div className="container cover-page">

            <div className="row row-cols-1 row-cols-md-4 g-4 jar-logos jar-div" style={{ justifyContent: "center" }}>

                <div className="col">
                    <a href="#">
                        <div className="card">
                        {seltheme === "dark" ? 
                        <img src={waggle_dark} className="card-img-top mt-sm-2 mb-sm-2" alt="..." />
                        :
                        <img src={waggle} className="card-img-top mt-sm-2 mb-sm-2" alt="..." />
                        }
                            <div className="card-body">
                                <h5 className="card-title">Waggle Dance</h5>
                            </div>
                        </div>
                    </a>
                </div>

                <div className="col">
                    <a href="#nectar">
                        <div className="card">
                            {seltheme === "dark" ? 
                            <img src={nectar_dark} className="card-img-top" alt="..." />
                            :
                            <img src={nectar} className="card-img-top" alt="..." />
                            }
                            <div className="card-body">
                                <h5 className="card-title">Nectar</h5>
                            </div>
                        </div>
                    </a>
                </div>
                <div className="col">
                    <a href="#nugget">
                        <div className="card">
                        {seltheme === "dark" ? 
                            <img src={nuggets_dark} className="card-img-top" alt="..." />
                            :
                            <img src={nuggets} className="card-img-top" alt="..." />
                        }
                            <div className="card-body">
                                <h5 className="card-title">Honey Nuggets</h5>
                            </div>
                        </div>
                    </a>
                </div>

                <div className="col">
                    <a href="#jelly">
                        <div className="card">
                        {seltheme === "dark" ? 
                        <img src={jelly_dark} className="card-img-top" alt="..." />
                        :
                        <img src={jelly} className="card-img-top" alt="..." />
                        }
                            <div className="card-body">
                                <h5 className="card-title">Royal Jelly</h5>
                            </div>
                        </div>
                    </a>
                </div>

                {/* <div className="col" style={{ width: "6%", padding:0 }}></div> */}

            </div>

        </div>
        </div>
    )
}

export default JarHeader