import axios from "axios";
import decode from "jwt-decode";
//import initialTokens from "../utilities/token.json";
const qs = require('qs');

export let baseAPIURL = '';
export let baseCEXURL = '';
export let baseDEXURL = '';
export let baseURL = '';
export let etherscanTxURL = '';
export let etherscanURL = 'https://etherscan.io/';
export let etherscanAddressURL = '';
export let bscscanTxURL = '';
export let bscscanURL = 'https://bscscan.com/';
export let bscscanAddressURL = '';
export let BSC_HTTPS_URL = "";
export let BSC_WSS_URL = "";
export let ETH_HTTPS_URL = "";
export let ETH_WSS_URL = "";
export let btcTxURL = "";
export let btcAddressURL = "";

export let baseHiveURL = '';
export let baseWSURL = '';
export let baseWalletURL = '';
export let baseShopURL = '';
export let baseXnftURL = '';
export let baseMktplaceURL = '';
export let baseAcadURL = '';

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  // baseCEXURL = "http://localhost:3001";
  baseCEXURL = "https://cex.indexx.ai";
  baseDEXURL = "https://dex.indexx.ai";
  baseAPIURL = "https://api.indexx.ai";
  baseURL = "https://indexx.ai";
  baseHiveURL = 'https://hive.indexx.ai';
  baseWSURL = 'https://wallstreet.indexx.ai';
  baseWalletURL = 'https://wallet.indexx.ai';
  baseShopURL = 'https://shop.indexx.ai';
  baseXnftURL = 'https://xnft.indexx.ai';
  baseMktplaceURL = 'https://xnftmarketplace.indexx.ai';
  baseAcadURL = 'https://academy.indexx.ai/';
  // baseAPIURL = "http://localhost:5000";
  etherscanTxURL = "https://etherscan.io/tx/";
  etherscanAddressURL = "https://etherscan.io/address/";
  bscscanTxURL = "https://bscscan.com/tx/";
  bscscanAddressURL = "https://bscscan.com/address/";
  BSC_HTTPS_URL = "https://nameless-virulent-gadget.bsc.discover.quiknode.pro/73f659d4424934f4781a1973e983a0419eca31fe/";
  BSC_WSS_URL = "wss://ws-nd-320-260-629.p2pify.com/da88590b0141b2fee72b4988283beaa3";
  ETH_HTTPS_URL = "https://mainnet.infura.io/v3/847e89cb852c43048f143456f82c32ec";
  ETH_WSS_URL = "wss://mainnet.infura.io/ws/v3/847e89cb852c43048f143456f82c32ec";
  btcAddressURL = "https://live.blockcypher.com/btc/address/";
  btcTxURL = "https://live.blockcypher.com/btc/tx/";
} else {
  baseCEXURL = "https://cex.indexx.ai";
  baseDEXURL = "https://dex.indexx.ai";
  baseAPIURL = "https://api.indexx.ai";
  baseURL = "https://indexx.ai";
  baseHiveURL = 'https://hive.indexx.ai';
  baseWSURL = 'https://wallstreet.indexx.ai';
  baseWalletURL = 'https://wallet.indexx.ai';
  baseShopURL = 'https://shop.indexx.ai';
  baseXnftURL = 'https://xnft.indexx.ai';
  baseMktplaceURL = 'https://xnftmarketplace.indexx.ai';
  baseAcadURL = 'https://academy.indexx.ai/';

  etherscanTxURL = "https://etherscan.io/tx/";
  etherscanAddressURL = "https://etherscan.io/address/";
  bscscanTxURL = "https://bscscan.com/tx/";
  bscscanAddressURL = "https://bscscan.com/address/";
  BSC_HTTPS_URL = "https://nameless-virulent-gadget.bsc.discover.quiknode.pro/73f659d4424934f4781a1973e983a0419eca31fe/";
  BSC_WSS_URL = "wss://ws-nd-320-260-629.p2pify.com/da88590b0141b2fee72b4988283beaa3";
  ETH_HTTPS_URL = "https://mainnet.infura.io/v3/847e89cb852c43048f143456f82c32ec";
  ETH_WSS_URL = "wss://mainnet.infura.io/ws/v3/847e89cb852c43048f143456f82c32ec";
  btcAddressURL = "https://live.blockcypher.com/btc/address/";
  btcTxURL = "https://live.blockcypher.com/btc/tx/";
}

const API = axios.create({
  baseURL: baseAPIURL,
});

export default baseAPIURL;

export const signupAPI = async (
  email,
  password,
  refferalCode
) => {
  try {
    const result = await API.post("/api/v1/inex/user/register", {
      email,
      password,
      refferalCode,
    });
    return result.data;
  } catch (e) {
    return e.response.data;
  }
};

export const checkUserName = async (
  username,
) => {
  try {
    const result = await API.post("/api/v1/inex/user/checkusername", {
      username,
    });
    return result.data;
  } catch (e) {
    return e.response.data;
  }
};

export const checkEmail = async (
  email,
) => {
  try {
    const result = await API.post("/api/v1/inex/user/checkemail", {
      email,
    });
    return result.data;
  } catch (e) {
    return e.response.data;
  }
};


export const loginAPI = async (email, password) => {
  try {
    const result = await API.post("/api/v1/inex/user/login", {
      email,
      password,
    });
    return result.data;
  } catch (e) {
    return e.response.data;
  }
};


export const logoutAPI = async () => { };

export const getCountries = async () => {
  try {
    const result = await API.post("/api/v1/inex/getCountries");
    return result.data;
  } catch (e) {
    return e.response.data;
  }
};

export const getUserWallet = async () => { };

export const verifyPhoneCode = async (code) => {
  try {
    const result = await API.post("/api/v1/inex/user/verifyPhoneCode", {
      code,
    });
    return result.data;
  } catch (e) {
    return e.response.data;
  }
};

export const getWalletBalance = async (email, coin) => {
  try {
    const result = await API.post(
      `/api/v1/inex/user/getBalance/${email}/${coin}`
    );
    if (result.status === 200) return result.data;
    else return result.data;
  } catch (e) {
    return e.response.data;
  }
};

export const getIndexxTokenPrices = async () => {
  try {
    const result = await API.post("/api/v1/inex/basic/indexxcoins");
    return result.data;
  } catch (e) {
    return e.response.data;
  }
};

export const getCryptoPrice = async (coin) => {
  try {
    const result = await API.post(`api/v1/inex/basic/getPriceByName`, {
      coin: coin,
    });
    return result.data;
  } catch (e) {
    return e.response.data;
  }
};

//https://api.coingecko.com/api/v3/coins/binancecoin/market_chart?vs_currency=USD&days=1&interval=hourly
export const getGraphicalCurrencyData = async (
  coinId,
  days,
  currency = "USD"
) => {
  let url = "";
  if (coinId === "IUSD+") {
    url = `https://api.coingecko.com/api/v3/coins/tether/market_chart?vs_currency=${currency}&days=${days}`;
  } else if (coinId === "INXC") {
    url = `https://api.coingecko.com/api/v3/coins/uma/market_chart?vs_currency=${currency}&days=${days}`;
  } else if (coinId === "IN500") {
    url = `https://api.coingecko.com/api/v3/coins/spdr-s-p-500-etf-trust-defichain/market_chart?vs_currency=${currency}&days=${days}`;
  } else if (coinId === "BTC") {
    url = `https://api.coingecko.com/api/v3/coins/bitcoin/market_chart?vs_currency=${currency}&days=${days}`;
  } else if (coinId === "BNB") {
    url = `https://api.coingecko.com/api/v3/coins/binancecoin/market_chart?vs_currency=${currency}&days=${days}`;
  } else if (coinId === "ETH") {
    url = `https://api.coingecko.com/api/v3/coins/ethereum/market_chart?vs_currency=${currency}&days=${days}`;
  } else if (coinId === "LTC") {
    url = `https://api.coingecko.com/api/v3/coins/litecoin/market_chart?vs_currency=${currency}&days=${days}`;
  } else if (coinId === "BUSD") {
    url = `https://api.coingecko.com/api/v3/coins/binance-usd/market_chart?vs_currency=${currency}&days=${days}`;
  } else if (coinId === "INEX") {
    url = `https://api.coingecko.com/api/v3/coins/stellar/market_chart?vs_currency=${currency}&days=${days}`;
  }
  let res = await fetch(url);
  return res;
};

export function isLoggedIn() {
  return !!getJwtToken();
}

export function getJwtToken() {
  return localStorage.getItem("access_token");
}

export function getRefreshToken() {
  var token = localStorage.getItem("refresh_token");
  return token;
}

export function storeTokens(tokens) {
  //exact jwt and store in local store as user object
  localStorage.setItem("access_token", tokens.access_token);
  localStorage.setItem("refreshToken", tokens.refresh_token);
}

export function removeTokens() {
  //remove from local store the user object
  localStorage.removeItem("access_token");
  localStorage.removeItem("refresh_token");
}


export const getAllCountries = async (email, coin) => {
  try {
    const result = await API.post("/api/v1/inex/getCountries");
    return result.data;
  } catch (e) {
    return e.response.data;
  }
};

export function decodeJWT(access_token) {
  let userObj = decode(access_token);
  return userObj;
}

export const getUserWallets = async (email) => {
  try {
    const result = await API.post(`/api/v1/inex/user/getUserWallets/${email}`);
    return result.data;
  } catch (e) {
    return e.response.data;
  }
};

export const checkAndUpdateDeposit = async (email, txHash, coin) => {
  try {
    const result = await API.post(`/api/v1/inex/transaction/createTx`, {
      email,
      txHash,
      coin
    });
    return result.data;
  } catch (e) {
    return e.response.data;
  }
}

export const createSellOrder1 = async (
  basecoin,
  quotecoin,
  amount,
  price,
  email
) => {
  try {
    const result = await API.post("/api/v1/inex/order/sell", {
      basecoin,
      quotecoin,
      amount,
      price,
      email,
    });
    return result.data;
  } catch (e) {
    return e.response.data;
  }
};

export const createConvertOrder1 = async (
  basecoin,
  quotecoin,
  amount,
  price,
  email
) => {
  try {
    const result = await API.post("/api/v1/inex/order/convert", {
      basecoin,
      quotecoin,
      amount,
      price,
      email,
    });
    return result.data;
  } catch (e) {
    return e.response.data;
  }
};

export const getAllTransactions = async (email) => {
  try {
    const result = await API.post(`/api/v1/inex/user/getTransactions/${email}`);
    return result.data;
  } catch (e) {
    return e.response.data;
  }
};

export const getUserRewardDetails = async (email) => {
  try {
    const result = await API.get(
      `/api/v1/inex/user/getUserRewardDetails/${email}`
    );
    return result.data;
  } catch (e) {
    return e.response.data;
  }
};

export const getCaptainBeeStatics = async (
  username,
  isPublicProfile = 'yes',
  userType = "CaptainBee"
) => {
  try {
    const result = await API.get(
      `/api/v1/affiliate/getAffiliateUserDashbaord/${username}/${isPublicProfile}/${userType}`
    );
    return result.data;
  } catch (e) {
    return e.response.data;
  }
};


export const getPublicMessagesByname = async (username) => {
  try {
    const result = await API.get(
      `/api/v1/inex/user/getpublicMessagesByName/${username}`
    );
    return result.data;
  } catch (e) {
    return e.response.data;
  }
};

export const updateRewardsWallet = async (
  email,
  walletAddr
) => {
  try {
    const result = await API.post(`/api/v1/inex/user/updateRewardsWallet`, {
      email: email,
      rewardWalletAddress: walletAddr,
    });
    return result.data;
  } catch (e) {
    return e.response.data;
  }
};

export const changePassword = async (
  email,
  newPassword,
  oldPassword
) => {
  try {
    const result = await API.post(`/api/v1/inex/user/changePassword`, {
      email: email,
      newPassword: newPassword,
      oldPassword: oldPassword
    });
    return result.data;
  } catch (e) {
    return e.response.data;
  }
};

export const forgotPassword = async (
  email,
) => {
  try {
    const result = await API.post(`/api/v1/inex/user/forgotPassword`, {
      email: email,
    });
    return result.data;
  } catch (e) {
    return e.response.data;
  }
};

export const resetPassword = async (
  email,
  password,
) => {
  try {
    const result = await API.post(`/api/v1/inex/user/resetPassword`, {
      email: email,
      password: password,
      code: "123"
    });
    return result.data;
  } catch (e) {
    return e.response.data;
  }
};

export const getUserDetails = async (email) => {
  try {
    const result = await API.post(`/api/v1/inex/user/getUserDetails/${email}`);
    return result.data;
  } catch (e) {
    return e.response.data;
  }
};

export const getCoinPriceByName = async (
  coin,
  type = "Buy"
) => {
  try {
    const result = await API.post(`/api/v1/inex/basic/getcoinprice/${coin}`, {
      type: type,
    });
    return result.data;
  } catch (e) {
    return e.response.data;
  }
};

export const getAppSettings = async () => {
  try {
    const result = await API.post("/api/v1/inex/basic/appSettings");
    return result.data;
  } catch (e) {
    return e.response.data;
  }
};

export const marketsData = async () => {
  try {
    const result = await API.get("/api/v1/inex/basic/marketPrice");
    return result.data;
  } catch (e) {
    return e.response.data;
  }
}

export const updateFavCurrencies = async (email, currency) => {
  try {
    const result = await API.post("/api/v1/inex/basic/updateFavCurrencies", {
      email: email,
      currency: currency
    });
    return result.data;
  } catch (e) {
    return e.response.data;
  }
}

export const createBuyOrder = async (
  basecoin,
  quotecoin,
  amount,
  price
) => {
  try {
    const result = await API.post("/api/v1/inex/order/createOrder", {
      currencyOut: basecoin,
      currencyIn: quotecoin,
      amount: amount,
      price: price,
      orderType: "Buy",
      email: localStorage.getItem("user"),
    });
    return result.data;
  } catch (e) {
    return e.response.data;
  }
};

export const createSellOrder = async (
  basecoin,
  quotecoin,
  amount,
  price
) => {
  try {
    const result = await API.post("/api/v1/inex/order/createOrder", {
      currencyOut: quotecoin,
      currencyIn: basecoin,
      amount: amount,
      price: price,
      orderType: "Sell",
      email: localStorage.getItem("user"),
    });
    return result.data;
  } catch (e) {
    return e.response.data;
  }
};

export const createExchange = async (
  userWalletAddress,
  inCurr,
  inAmount,
  outCurr,
  outAmount,
  orderType,
  orderRate,
  USDValue,
  blockchain,
  userBankDetails = {}
) => {
  try {
    const userConnectedAddress = localStorage.getItem("userWalletAddress");
    const result = await API.post("/api/v1/dex/user/createExchange", {
      userWalletAddress: userWalletAddress,
      inCurr: inCurr,
      inAmount: inAmount,
      outCurr: outCurr,
      outAmount: outAmount,
      orderType: orderType,
      orderRate: orderRate,
      USDValue: USDValue,
      userBankDetails: userBankDetails,
      blockchain: blockchain,
      metaMaskAddress: (userConnectedAddress === undefined || userConnectedAddress === null || userConnectedAddress === "") ? userWalletAddress : userConnectedAddress
    });
    return result.data;
  } catch (e) {
    return e.response.data;
  }
}

export const getDEXOrderDetails = async (
  orderId
) => {
  try {
    const result = await API.get(`/api/v1/dex/user/orderById/${orderId}`);
    return result.data;
  } catch (e) {
    return e.response.data;
  }
}


export const getDEXUserRewards = async (
  userWalletAddress
) => {
  try {
    const result = await API.get(`/api/v1/dex/user/gettradetoearnrewards/${userWalletAddress}`);
    return result.data;
  } catch (e) {
    return e.response.data;
  }
}

export const getConfirmStatus = async (
  orderId
) => {
  try {
    const result = await API.get(`/api/v1/dex/user/confirmStatus/${orderId}`);
    return result.data;
  } catch (e) {
    return e.response.data;
  }
}

export const getDepositStatus = async (
  orderId
) => {
  try {
    const result = await API.get(`/api/v1/dex/user/depositStatus/${orderId}`);
    return result.data;
  } catch (e) {
    return e.response.data;
  }
}

export const dexCheckAndUpdateDeposit = async (
  depositHash,
  orderDetails
) => {
  try {
    const result = await API.post("/api/v1/dex/user/checkAndUpdateDeposit", {
      txHash: depositHash,
      orderDetails: orderDetails
    });
    return result.data;
  } catch (e) {
    return e.response.data;
  }

}

export const checkAndUpdateExchange = async (
  orderId
) => {
  try {
    const result = await API.post("/api/v1/dex/user/checkAndUpdateExchange", {
      orderId: orderId
    });
    return result.data;
  } catch (e) {
    return e.response.data;
  }
}

export const getExchangeStatus = async (
  orderId
) => {
  try {
    const result = await API.get(`/api/v1/dex/user/exchangeStatus/${orderId}`);
    return result.data;
  } catch (e) {
    return e.response.data;
  }
}

export const confirmSellOrder = async (
  emal,
  orderId,
  orderStatus,
  basecoin
) => {
  try {
    const result = await API.post("/api/v1/inex/order/updateOrder", {
      email: emal,
      orderId: orderId,
      orderStatus: orderStatus,
      currencyIn: basecoin,
    });
    return result.data;
  } catch (e) {
    return e.response.data;
  }
};

export const createConvertOrder = async (
  basecoin,
  quotecoin,
  amount,
  price
) => {
  try {
    const result = await API.post("/api/v1/inex/order/createOrder", {
      currencyOut: quotecoin,
      currencyIn: basecoin,
      amount: amount,
      price: price,
      orderType: "Convert",
      email: localStorage.getItem("user"),
    });
    return result.data;
  } catch (e) {
    return e.response.data;
  }
};

export const confirmConvertOrder = async (
  emal,
  orderId,
  orderStatus = "Completed",
  basecoin = "IN500"
) => {
  try {
    const result = await API.post("/api/v1/inex/order/processCovert", {
      email: emal,
      orderId: orderId,
    });
    return result.data;
  } catch (e) {
    return e.response.data;
  }
};

export const createStripePaymentIntent = async (
  amount,
  orderId,
  email
) => {
  try {
    const result = await API.post("/api/v1/inex/stripe/createPaymentIntent", {
      amount: amount,
      orderId: orderId,
      email: email,
    });
    return result.data;
  } catch (e) {
    return e.response.data;
  }
};

export const createDEXStripePaymentIntent = async (
  amount,
  orderId,
  userWallerAddress
) => {
  try {
    const result = await API.post("/api/v1/dex/user/stripe/createPaymentIntent", {
      amount: amount,
      orderId: orderId,
      userWallerAddress: userWallerAddress
    });
    return result.data;
  } catch (e) {
    return e.response.data;
  }
};

export const oneUSDHelper = async (coinValue, coinType) => {
  try {
    let oneUSDValue = 0;
    if (coinType === "IN500") {
      oneUSDValue = 1 / coinValue;
    } else if (coinType === "INXC") {
      oneUSDValue = 1 / coinValue;
    } else if (coinType === "IUSD+") {
      oneUSDValue = 1;
    } else if (coinType === "BUSD") {
      oneUSDValue = 1 / coinValue;
    } else if (coinType === "BTC") {
      oneUSDValue = 1 / coinValue;
    } else if (coinType === "ETH") {
      oneUSDValue = 1 / coinValue;
    } else if (coinType === "BNB") {
      oneUSDValue = 1 / coinValue;
    } else if (coinType === "LTC") {
      oneUSDValue = 1 / coinValue;
    } else if (coinType === "INEX") {
      oneUSDValue = 1 / coinValue;
    } else {
      oneUSDValue = 0.1;
    }
    return oneUSDValue;
  } catch (err) {
    return 0;
  }
};

export const getMinAndMaxOrderValues = async (
  coin,
  orderType
) => {
  try {
    const result = await API.post("/api/v1/inex/basic/orderMinMax", {
      currency: coin,
      orderType: orderType,
    });
    return result.data;
  } catch (e) {
    return e.response.data;
  }
};

export const withdrawINEX = async (email, amount) => {
  try {
    const result = await API.post("/api/v1/inex/user/withdrawRewards", {
      email: email,
      amount: amount,
    });
    return result.data;
  } catch (e) {
    return e.response.data;
  }
};

export const transactionList = async (email, type = 'FIAT') => {
  try {
    const result = await API.post(`/api/v1/inex/user/getTransactions/${email}`, {
      type: type,
    });
    return result.data;
  } catch (err) {
    return err.response.data;
  }
};

export const createFiatWithdraw = async (email, amount, accountDetails, coin = 'USD') => {
  try {
    const result = await API.post("/api/v1/inex/transaction/createFiatWithdraw", {
      email: email,
      amount: amount,
      accountDetails: accountDetails,
      coin: coin
    });
    return result.data;
  } catch (e) {
    return e.response.data;
  }
};

export const createCryptoWithdraw = async (email, amount, address, coin = 'USD') => {
  try {
    const result = await API.post("/api/v1/inex/transaction/createCryptoWithdraw", {
      email: email,
      amount: amount,
      address: address,
      coin: coin
    });
    return result.data;
  } catch (e) {
    return e.response.data;
  }
};

export const createFiatDeposit = async (email, amount, txHash, coin = 'USD') => {
  try {
    const result = await API.post("/api/v1/inex/transaction/createFiatDeposit", {
      email: email,
      amount: amount,
      txHash: txHash,
      coin: coin
    });
    return result.data;
  } catch (e) {
    return e.response.data;
  }
};

export const resendEmailCode = async (email) => {
  try {
    const result = await API.post("/api/v1/inex/user/resendEmailCode", {
      email: email,
    });
    return result.data;
  } catch (e) {
    return e.response.data;
  }
}

export const validateEmail = async (email, code) => {
  try {
    const result = await API.post("/api/v1/inex/user/validateEmail", {
      email: email,
      code: code
    });
    return result.data;
  } catch (e) {
    return e.response.data;
  }
}

export const getOrderDetails = async (email, orderId) => {
  try {
    const result = await API.get(
      `/api/v1/inex/user/getUserOrder/${email}/${orderId}`
    );
    return result.data;
  } catch (e) {
    return e.response.data;
  }
};


export const getPaypalOrder = async (token) => {
  try {
    const result = await API.get(`/api/v1/inex/user/getPaypalOrder/${token}`);
    return result.data;
  } catch (e) {
    return e.response.data;
  }
};

export const subscribeBlockchain = async (orderId) => {
  try {
    const result = await API.post(`/api/v1/dex/user/startSubcribe/`, {
      orderId: orderId
    });
    return result.data;
  } catch (e) {
    return e.response.data;
  }
}

export const confirmAndExchange = async (txDetails, orderId) => {
  try {
    const result = await API.post(`/api/v1/dex/user/checkAndConfirmExchange`, {
      txDetails: txDetails,
      orderId: orderId
    });
    return result.data;
  } catch (err) {
    return err.response.data;
  }
}

export const getUniswapTokens = async () => {
  try {
    let getTokens = await axios.get('https://tokens.coingecko.com/uniswap/all.json');
    return getTokens.data.tokens;
  } catch (err) {
    return err.response.data;
  }
}

export const getPancakeTokens = async () => {
  try {
    let getTokens = await axios.get('https://tokens.coingecko.com/binance-smart-chain/all.json');
    return getTokens.data.tokens;
  } catch (err) {
    return err.response.data;
  }
}

export const getAllAffiliateUser = async () => {
  try {
    const result = await API.get(`/api/v1/affiliate/getAllaffiliateUsers`);
    const formattedData = result.data.data.map(item => {
      return {
        ...item._doc,
        userData: item.userData
      };
    });
    
    return formattedData;
  } catch (err) {
    return err.response.data;
  }
}

export const getTokenPrices = async (blockchainSymbol, sellToken, buyToken, sellAmount) => {
  try {
    const baseURL = blockchainSymbol === "ETH" ? "https://api.0x.org" : "https://bsc.api.0x.org";

    const params = {
      sellToken: sellToken,
      buyToken: buyToken,
      sellAmount: (sellAmount).toString(),
      feeRecipient: "0x35EAd16cbd74AB6380aB9ad91266dc407149652f",
      buyTokenPercentageFee: 0.1
    }
    const params1 = {
      sellToken: sellToken,
      buyToken: buyToken,
      sellAmount: (sellAmount).toString(),
      feeRecipient: "0x35EAd16cbd74AB6380aB9ad91266dc407149652f",
      buyTokenPercentageFee: 0.1
    }
    let responseWithFees = await axios.get(`${baseURL}/swap/v1/price?${qs.stringify(params)}`);
    let responseWithOutFees = await axios.get(`${baseURL}/swap/v1/price?${qs.stringify(params1)}`);
    return { responseWithFees: responseWithFees.data, responseWithOutFees: responseWithOutFees.data };
  } catch (err) {
    return err.response.data;
  }
}

export const getQuotes = async (blockchainSymbol, sellToken, buyToken, sellAmount, account) => {
  try {
    const baseURL = blockchainSymbol === "ETH" ? "https://api.0x.org" : "https://bsc.api.0x.org";
    const params = {
      sellToken: sellToken,
      buyToken: buyToken,
      sellAmount: (sellAmount).toString(),
      //takerAddress: account
    }
    let response = await axios.get(`${baseURL}/swap/v1/quote?${qs.stringify(params)}`);
    return response.data;
  } catch (err) {
    return err.response.data;
  }
}

export const getQuotesWithTaker = async (blockchainSymbol, sellToken, buyToken, sellAmount, account) => {
  let response;
  try {
    const baseURL = blockchainSymbol === "ETH" ? "https://api.0x.org" : "https://bsc.api.0x.org";
    const params = {
      sellToken: sellToken,
      buyToken: buyToken,
      sellAmount: (sellAmount).toString(),
      takerAddress: account,
      feeRecipient: "0x35EAd16cbd74AB6380aB9ad91266dc407149652f",
      buyTokenPercentageFee: 0.1
    }
    response = await axios.get(`${baseURL}/swap/v1/quote?${qs.stringify(params)}`);
    return response.data;
  } catch (err) {
    //throw new Error("Couldn't proceed swap");
    return response;
  }
}

export const getMarketData = async (blockchainSymbol, contractAddress, days) => {
  try {
    const blockchain = blockchainSymbol === "ETH" ? "ethereum" : "binance-smart-chain";
    let from, to;
    if (contractAddress === "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee" && blockchainSymbol === "BNB") {
      contractAddress = "0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c"
    } else if (contractAddress === "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee" && blockchainSymbol === "ETH") {
      contractAddress = "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2";
    }
    const getTimeStamps = await timeStamps(days);
    from = getTimeStamps.from;
    to = getTimeStamps.to;
    let response = await axios.get(`https://api.coingecko.com/api/v3/coins/${blockchain}/contract/${contractAddress}/market_chart/range?vs_currency=usd&from=${from}&to=${to}`);
    return response.data;
  }
  catch (err) {
    throw new Error("Couldn't fetch Graph");
  }
}

export const getPriceAndChangeData = async (blockchainSymbol, contractAddress) => {
  try {
    const blockchain = blockchainSymbol === "ETH" ? "ethereum" : "binance-smart-chain";
    if (contractAddress === "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee" && blockchainSymbol === "BNB") {
      contractAddress = "0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c"
    } else if (contractAddress === "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee" && blockchainSymbol === "ETH") {
      contractAddress = "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2";
    }
    let response = await axios.get(`https://api.coingecko.com/api/v3/coins/${blockchain}/contract/${contractAddress}`);
    return response.data;
  } catch (err) {
    throw new Error("Couldn't fetch Graph", err);
  }
}

export const getGrahDataForTwoTokens = async (token1, token2, blockchain, days) => {
  try {
    const token1Data = await getMarketData(blockchain, token1, days);
    const token2Data = await getMarketData(blockchain, token2, days);
    const token1Prices = token1Data.prices;
    const token2Prices = token2Data.prices;
    if (token1Prices.length === 0 || token2Prices.length === 0) {
      throw new Error("Insufficient Data");
    }
    const averagePrices = await calculateAverage(token1Prices, token2Prices);
    return averagePrices;
  } catch (err) {
    throw new Error("Couldn't fetch Graph");
  }
}


export const getPriceAndChangeDataForTwoTokens = async (token1, token2, blockchain, days) => {
  try {
    const token1Data = await getPriceAndChangeData(blockchain, token1);
    const token2Data = await getPriceAndChangeData(blockchain, token2);
    const token1Price = token1Data.market_data.current_price['usd'];
    const token2Price = token2Data.market_data.current_price['usd'];
    let token1Change = 0;
    let token2Change = 0;
    if (days === 1) {
      token1Change = token1Data.market_data.price_change_percentage_24h;
      token2Change = token2Data.market_data.price_change_percentage_24h;
    } else if (days === 7) {
      token1Change = token1Data.market_data.price_change_percentage_7d;
      token2Change = token2Data.market_data.price_change_percentage_7d;
    } else if (days === 30) {
      token1Change = token1Data.market_data.price_change_percentage_30d;
      token2Change = token2Data.market_data.price_change_percentage_30d;
    } else if (days === 365) {
      token1Change = token1Data.market_data.price_change_percentage_1y;
      token2Change = token2Data.market_data.price_change_percentage_1y;
    } else if (days === 0.04) {
      token1Change = token1Data.market_data.price_change_percentage_1h_in_currency['usd'];
      token2Change = token2Data.market_data.price_change_percentage_1h_in_currency['usd'];
    }
    const averagePrice = (token1Price / token2Price);
    const averageChange = (token1Change / token2Change);
    return { averagePrice, averageChange };
  } catch (err) {
    throw new Error("Couldn't fetch Graph", err);
  }
}

export const createBitcoinWallet = async () => {
  try {
    const response = await API.get(`/api/v1/dex/user/generateBitcoinAddress`);
    return response;
  } catch (err) {
    throw new Error("Couldn't create wallet");
  }
}

const calculateAverage = async (arr1, arr2) => {
  try {
    let length = Math.min(arr1.length, arr2.length);
    return Array.from({ length: length }, (_, i) => [arr1[i][0], arr1[i][1] / arr2[i][1]]);
  } catch (err) {
    return err;
  }

}

const timeStamps = async (days) => {
  try {
    // Today
    var today = new Date();
    let to, from;
    to = today.getTime();
    if (days === 1) {
      // Yesterday
      today.setDate(today.getDate() - 1);
      from = today.getTime();
    } else if (days === 7) {
      // One week ago
      today.setDate(today.getDate() - 7);
      from = today.getTime();
    } else if (days === 31) {
      // One month ago
      today.setMonth(today.getMonth() - 1);
      from = today.getTime();
    } else if (days === 365) {
      // One year ago
      today.setFullYear(today.getFullYear() - 1);
      from = today.getTime();
    } else if (days === 0.04) {
      // One hour ago
      today.setHours(today.getHours() - 1);
      from = today.getTime();
    }
    return { to: Math.round(to / 1000), from: Math.round(from / 1000) }
  } catch (err) {
    throw new Error("Couldn't fetch Graph");
  }
}