import React from 'react'
import telephone from "../../assets/Homepage/telephone 1.svg";
import { Box, Button, Typography, TextField, useMediaQuery } from '@mui/material';
import { useTheme } from '@emotion/react';
import { useState } from 'react';


const ContactUs = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const [name, setName] = useState("");
    const [Email, setEmail] = useState("");
    const [message, setMessage] = useState("");

    const [emailError, setEmailError] = useState('');

    const validateEmail = () => {
        const isValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(Email);
        setEmailError(isValid ? '' : 'Invalid email address');
        return isValid;
      };

  return (
    <Box sx={{
        display: "flex",
        flexDirection: `${isMobile ? "column" : "row"}`,
        justifyContent:`${isMobile ? "center" : "space-between"}`,
        alignItems: "center",
        width: `${isMobile ? "60%" : "70%"}`,
        mx: "auto",
        my: 25,
        maxWidth:"1520px"
    }}>
        <Box width={isMobile? "100%": "35%"} sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: `${isMobile ? "center" : "flex-start"}`,
            justifyContent:`${isMobile ? "center" : "space-between"}`
        }}>
            <Box
                component="img"
                sx={{
                    width: `${isMobile ? "20%" : "auto"}`,
                    // width: "100%",
                    // mb:4
                }}
                alt=""
                src={telephone}
            />
            <Typography variant="text" fontSize={isMobile? "32px" : "50px"} fontWeight={600} textAlign={"left"}>
                Connect with us
            </Typography>

            <Typography
                fontSize={isMobile? "11px" : "13px"}
                fontFamily={"Inter"}
                fontWeight={400}
                lineHeight={"28px"}
                textAlign={"left    "}
            >
                Stay connected to discover new horizons and possibilities. Join our vibrant community at Indexx Hive and unlock a world of growth and collaboration.
            </Typography>

        </Box>
        <Box width={isMobile? "100%": "55%"}>
            <TextField
                label="Name"
                variant="outlined"
                sx={{ mb: 2, width: '100%' }}
                size="small" // Make the input box smaller
                value={name}
                onChange={(e) => {
                    setName(e.target.value);
                }}
            />

        <TextField
            label="Email"
            variant="outlined"
            sx={{ mb: 2, width: '100%' }}
            size="small" // Make the input box smaller
            type='email'
            value={Email}
            error={emailError !== ''}
            helperText={emailError}
            onBlur={validateEmail}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />

            <TextField
                label="Message"
                variant="outlined"
                sx={{ mb: 2, width: '100%' }}
                size="small" // Make the input box smaller
                value={message}
                onChange={(e) => {
                    setMessage(e.target.value);
                }}
            />
            <Button variant='contained'
              disableTouchRipple
                sx={{
                    backgroundColor: "#FFB300",
                    borderRadius: "2px",
                    color: "#282828",
                    width: "100%",
                    px: 10,
                    py: 1,
                    textTransform: "none",
                    fontSize: "13px",
                    fontWeight: 700,
                    boxShadow: "none",
                    mt: 3,
                    "&:hover": {
                        backgroundColor: "#FFD000",
                        boxShadow: "none",
                    },
                }}
            >
                Submit
            </Button>

        </Box>
    </Box>
  )
}

export default ContactUs