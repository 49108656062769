import { Box, Typography, useMediaQuery } from '@mui/material'
import React from 'react'
// import jar from "../../assets/details/doublejar.svg";
import waggledance from "../../assets/details/waggle dance.png";
import nectar from "../../assets/details/nectar.png";
import nugget from "../../assets/details/nuggets2.png";
import jelly from "../../assets/details/Royal Jelly.png";

import '../LandingHeader/LandHeader.css'
import JarHeader from './JarHeader';
import SubHeader from '../SubHeader/SubHeader';
import { useTheme } from '@emotion/react';

const HoneyJarB2C = () => {
  
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <>

    <SubHeader />
    <JarHeader/>
    <Box mt={35}>
    <Box sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        // width: "40%",
        width: `${isMobile ? "52%" : "40%"}`,
        mx: "auto",
        mb: 25,
        mt:48
    }}>

        <Box
            component="img"
            alt=""
            src={waggledance}
            mb={isMobile? "0px" : "-20px"}
            width={isMobile? "55%" : "auto"}
        />
            <Typography variant="text" fontSize={isMobile? "31px" : "80px"} fontWeight={600} textAlign={"left"}>
                Waggle Dance
            </Typography>
        <Box width={isMobile? "auto" : "523.67px"} sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            alignSelf: "center",
            ml:isMobile ? 0.8 : 1.2
        }}>
            <Typography variant="text" fontSize={isMobile? "15px" : "25px"} fontWeight={400} textAlign={"left"}>
                The Investment Portfolio
            </Typography>
            <Typography
                fontSize={isMobile? "11px" : "13px"}
                fontFamily={"Inter"}
                fontWeight={400}
                lineHeight={"25px"}
                textAlign={"left"}
                id="nectar"
                // width={"450.74px"}
            >
            The Waggle Dance is like a control center for the Captain Bees. It's a growth portfolio dashboard that allows them to manage the movement of stocks and keep track of the progress of each HoneyBee. This ensures transparency, control, and effective mentoring within the ecosystem.
            </Typography>



        </Box>
        
    </Box>
    
    <Box sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "40%",
        // width: `${isMobile ? "40%" : "40%"}`,
        mx: "auto",
        mb: 10,
        mt:23,
    }}>

        <Box
            component="img"
            alt=""
            src={nectar}
            mb={isMobile? "-15px" : "-40px"}
            width={isMobile? "60%" : "auto"}
        />
            <Typography variant="text" fontSize={isMobile? "50px" : "80px"} fontWeight={600} textAlign={"left"}>
                Nectar
            </Typography>
        <Box width={isMobile ? "auto" : "241.76px"} sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            alignSelf: "center",
            ml:isMobile ? 0.8 : 1.2
        }}>
            <Typography variant="text" fontSize={isMobile? "15px" : "25px"} fontWeight={400} textAlign={"left"}>
                Staking and Earning
            </Typography>
            <Typography
                fontSize={isMobile? "11px" : "13px"}
                fontFamily={"Inter"}
                fontWeight={400}
                lineHeight={"25px"}
                textAlign={"left"}
                // id="nugget"
            >
            The Nectar serve as a financial tool within the Hive. It's a staking platform where both Captain Bees and HoneyBees can invest their stocks and cryptocurrencies. 
            In return, they earn interest-based returns on their investments. This adds a layer of financial 
            <div id='nugget'></div>
            growth and sustainability to the Hive. the Nectar is the interest and bonus of staking the Stock Certificate, Stock Tokens and the other deposited assets in indexx
            </Typography>
        </Box>
    </Box>
    <Box sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        // width: "47%",
        width: `${isMobile ? "60%" : "47%"}`,
        mx: "auto",
        mb: 25,
        mt:isMobile ? 19 : 23
    }}>

        <Box
            component="img"
            alt=""
            src={nugget}
            mb={isMobile? "-12px" : "-40px"}
            width={isMobile? "38%" : "auto"}
        />
         
            <Typography variant="text" fontSize={isMobile? "32px" : "80px"} fontWeight={600} textAlign={"left"}>
                Honey Nuggets
            </Typography>
           
        <Box width={isMobile? "auto" : "563.8px"} sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            alignSelf: "center",
            ml:isMobile ? 0.65 : 0.6
        }}>
             <Typography variant="text" fontSize={isMobile? "15px" : "25px"} fontWeight={400} textAlign={"left"}>
            Products in Web-2 Category
            </Typography>
            <Typography
                fontSize={isMobile? "11px" : "13px"}
                fontFamily={"Inter"}
                fontWeight={400}
                lineHeight={"25px"}
                textAlign={"left"}
                id="jelly"
                ml={isMobile ? 0.1 : 0.3}
            >
            This is the collective term for the engaged community of users, investors, and buyers who actively participate in the WEb-2 Categories of ecosystem.
            </Typography>



        </Box>
        
    </Box>
    <Box
  sx={{
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: `${isMobile ? "55%" : "50%"}`,
    mx: "auto",
    my: 23,
  }}
>
  <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center", // Center align the contents
    }}
  >
    <Box
      component="img"
      alt=""
      src={jelly} 
      width={isMobile ? "35%" : "167px"}
      mb={isMobile ? "-10px" : "-20px"}
    />

    <Typography
      variant="text"
      fontSize={isMobile? "44.2px" : "80px"}
      fontWeight={600}
      textAlign={"center"} // Center align the heading text
     
    >
      Royal Jelly
    </Typography>
   
    <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    }}
  >
    
    <Typography
      fontSize={isMobile? "11px" : "13px"}
      fontFamily={"Inter"}
      fontWeight={400}
      lineHeight={"28px"}
      textAlign={"left"}
      width={isMobile ? "auto" : "368px"} // Adjust the width as needed
      mt={isMobile ? 1 : 2}
      ml={isMobile ? 0.8 : 1}
    >
         <Typography variant="text" fontSize={isMobile? "15px" : "25px"} fontWeight={400} textAlign={"left"}>Products in Web-3 Category</Typography>
         <br/>
      Royal Jelly is a comprehensive platform that provides insights into the
      performance of crypto and stock tokens within the Hive. It's a hub for
      tracking and understanding how different assets are performing within the
      larger Web3 landscape.
    </Typography>
  </Box>
  </Box>


</Box>
    </Box>
    </>

  )
}

export default HoneyJarB2C