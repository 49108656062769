import React, { useState, useEffect } from "react";
import earn from "../../assets/franchise/Group 1318.svg";
import earn_dark from "../../assets/franchise/earn_dark.svg";
import earnings_mobile from "../../assets/franchise/earnings_mobile.svg";
import earnings_mobile_dark from "../../assets/franchise/earnings_mobile_dark.svg";
import leader from "../../assets/franchise/Leadership.png";
import whale from "../../assets/franchise/Whale.png";
import first_banner from "../../assets/franchise/header graphic.png";
import community from "../../assets/franchise/people 4.svg";
import community_dark from "../../assets/franchise/community_dark.svg";
import community_mobile from "../../assets/franchise/community_mobile.svg";
import community_mobile_dark from "../../assets/franchise/community_mobile_dark.svg";
import PowerPackImg from "../../assets/hivebanner/powerpack.png";
import cryptoPackImg from "../../assets/hivebanner/crypto.png";
import { Box, Button, Typography, useMediaQuery } from "@mui/material";
// import { Link } from 'react-router-dom';
import Bees from "../CaptainBees/CaptainBees";
import HoneyComb from "../HoneyComb/HoneyComb";
import SubHeader from "../SubHeader/SubHeader";
import { useTheme } from "@emotion/react";
import ContactUs from "../ContactUs/ContactUs";
const Franchise = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const [seltheme, setSeltheme] = useState(
    localStorage.getItem("selectedTheme") || "dark"
  );

  useEffect(() => {
    setTimeout(() => window.scrollTo(0, 0), 0);
  }, []);

  useEffect(() => {
    const handleStorageChange = (event) => {
      console.log(event);
      setSeltheme(event.currentTarget.localStorage.selectedTheme);
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  return (
    <>
      <SubHeader />
      <Bees />
      <Box mt={30}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            mt: 10,
          }}
        >
          <Box
            component="img"
            sx={{
              width: `${isMobile ? "100%" : "54%"}`,
            }}
            alt=""
            src={first_banner}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              margin: "auto",
              backgroundColor: "#FFD000",
              color: "#282828",
            }}
          >
            <Typography
              variant="text"
              fontSize={isMobile ? "20px" : "70px"}
              fontWeight={700}
              mt={2}
            >
              Ready, Set, Join the Family
            </Typography>
            <Typography
              variant="text"
              fontSize={isMobile ? "11px" : "15px"}
              fontWeight={500}
              textAlign={"left"}
              mb={4}
              mt={1}
            >
              Explore and learn the ins and outs of Indexx Hive family.
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            mt: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              width: `${isMobile ? "59.2%" : "27%"}`,
              margin: "auto",
              maxWidth: "1520px",
            }}
          >
            <Button
              variant="contained"
              disableTouchRipple
              sx={{
                backgroundColor: "#FFB300",
                borderRadius: "2px",
                color: "#282828",
                width: `${isMobile ? "100%" : "410.18px"}`,
                px: isMobile ? "auto" : 10,
                py: 1,
                textTransform: "none",
                fontSize: "13px",
                fontWeight: "600",
                boxShadow: "none",
                mt: 2,
                "&:hover": {
                  backgroundColor: "#FFD000",
                  boxShadow: "none",
                },
              }}
            >
              <a
                href="/sign-up"
                style={{
                  textDecoration: "none", // Set textDecoration to "none" here
                  color: "#282828", // Add the color style for the anchor tag
                }}
              >
                {" "}
                Sign Up as Captain Bee
              </a>
            </Button>
          </Box>
        </Box>
        <div className="powerpack_banner">
          <div style={{ transform: "translateY(50px)" }}>
            <h3
              style={{
                color: "black",
                fontSize: "52px",
                marginTop: "50px",
                fontWeight: "bold",
              }}
            >
              Power Packs
            </h3>
            <a
              href="https://cex.indexx.ai/indexx-exchange/power-pack"
              className="power-link"
            >
              Buy{" >"}
            </a>
          </div>
          <div
            className="BannerImgContainer"
            style={{
              width: "100%",

              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src={PowerPackImg} style={{ width: "80%" }} />
          </div>
        </div>
        <div className="cryptopack_banner">
          <div style={{ transform: "translateY(50px)", marginBottom: "120px" }}>
            <h3
              style={{
                color: "white",
                fontSize: "52px",
                marginTop: "50px",
                fontWeight: "bold",
              }}
            >
              Crypto Packs
            </h3>
            <a
              href="https://cex.indexx.ai/indexx-exchange/power-pack"
              className="crypto-link"
            >
              Buy{" >"}
            </a>
          </div>
          <div
            className="BannerImgContainer"
            style={{
              width: "100%",

              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src={cryptoPackImg} style={{ width: "80%" }} />
          </div>
        </div>

        <Box
          sx={{
            display: "flex",
            // flexDirection: "row",
            flexDirection: `${isMobile ? "column" : "row"}`,
            justifyContent: "center",
            alignItems: "center",
            width: `${isMobile ? "58%" : "80%"}`,
            mx: "auto",
            my: 25,
            maxWidth: "1520px",
          }}
        >
          {isMobile && (
            <Box
              component="img"
              sx={{
                // width: "39%",
                width: `${isMobile ? "100%" : "39%"}`,
              }}
              alt=""
              src={leader}
            />
          )}
          <Box
            // width={"55%"}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              alignSelf: "self-end",
              pb: "48px",
            }}
          >
            <Typography
              variant="text"
              fontSize={isMobile ? "15px" : "20px"}
              fontWeight={400}
              textAlign={"left"}
              mb={isMobile ? "0" : "-20px"}
            >
              Secure your
            </Typography>
            <Typography
              variant="text"
              fontSize={isMobile ? "25px" : "80px"}
              fontWeight={600}
              textAlign={"left"}
              ml={"-4px"}
            >
              Financial Leadership
            </Typography>

            <Typography
              fontSize={isMobile ? "11px" : "13px"}
              fontFamily={"Inter"}
              fontWeight={400}
              lineHeight={"28px"}
              textAlign={"left"}
              width={isMobile ? "auto" : "725.71px"}
              // ml={1}
            >
              Registering as a Captain Bee secures your position as a financial
              leader within the Indexx Hive. Your guidance drives the investment
              experiences of others, while the exclusive percentage you earn
              solidifies your own status as a pioneer in the cryptocurrency
              landscape.
            </Typography>

            {/* <Button 
                // variant='outlined' 
                disableTouchRipple
                sx={{
                  color:"#FFB300",
                  px: 0,
                  textTransform:"none",
                  fontSize:"13px",
                  mt:4,
                  "&:hover": {
                    backgroundColor : "transparent",
                  },
                }}
                >
                    Learn More
                </Button> */}
          </Box>
          {!isMobile && (
            <Box
              component="img"
              sx={{
                width: "39%",
              }}
              alt=""
              src={leader}
            />
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: `${isMobile ? "column" : "row"}`,
            justifyContent: "space-between",
            alignItems: "center",
            width: `${isMobile ? "58%" : "95%"}`,
            mx: "auto",
            my: 25,
            maxWidth: "1520px",
          }}
        >
          <Box
            component="img"
            sx={{
              width: `${isMobile ? "100%" : "50.5%"}`,
            }}
            alt=""
            src={whale}
          />
          <Box
            // width={"55%"}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              ml: isMobile ? 0 : "-60px",
            }}
          >
            <Typography
              variant="text"
              fontSize={isMobile ? "15px" : "20px"}
              fontWeight={400}
              textAlign={"left"}
              mb={"-20px"}
              mt={isMobile ? 0 : 5}
            >
              Create a Legacy of
            </Typography>
            <Typography
              variant="text"
              fontSize={isMobile ? "50px" : "80px"}
              fontWeight={600}
              textAlign={"left"}
              ml={"-5px"}
            >
              Prosperity
            </Typography>

            <Typography
              fontSize={isMobile ? "11px" : "13px"}
              fontFamily={"Inter"}
              fontWeight={400}
              lineHeight={"28px"}
              textAlign={"left"}
              width={isMobile ? "auto" : "368.71px"}
            >
              As a Captain Bee, you aren't just influencing investments today;
              you're creating a legacy of prosperity for yourself and those you
              guide. Your exclusive earnings reflect the lasting impact you make
              on the financial success of the Hive.
            </Typography>

            {/* <Button 
                // variant='outlined' 
                disableTouchRipple
                sx={{
                  color:"#FFB300",
                  px: 0,
                  textTransform:"none",
                  fontSize:"13px",
                  mt:4,
                  "&:hover": {
                    backgroundColor : "transparent",
                  },
                }}
                >
                    Learn More
                </Button> */}
          </Box>
          <Box></Box>
          <Box></Box>
          <Box></Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img
            alt=""
            src={
              isMobile
                ? seltheme === "dark"
                  ? earnings_mobile_dark
                  : earnings_mobile
                : seltheme === "dark"
                ? earn_dark
                : earn
            }
            width={isMobile ? "80%" : "auto"}
          />
          {/* <Button 
                disableTouchRipple
                // variant='outlined' 
                sx={{
                  color:"#FFB300",
                  px: 0,
                  textTransform:"none",
                  fontSize:"13px",
                  mt:4,
                  "&:hover": {
                    backgroundColor : "transparent",
                  },
                }}
                >
                    Learn More
                </Button> */}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            mt: 25,
          }}
        >
          <Box
            component="img"
            alt=""
            src={
              isMobile
                ? seltheme === "dark"
                  ? community_mobile_dark
                  : community_mobile
                : seltheme === "dark"
                ? community_dark
                : community
            }
            width={isMobile ? "100%" : "auto"}
          />
        </Box>
      </Box>
      <HoneyComb />
      <ContactUs />
    </>
  );
};

export default Franchise;
