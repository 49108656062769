import React, { useState, useEffect } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const Policies = () => {
  const [numPages, setNumPages] = useState(null);
  // const [pageNumber, setPageNumber] = useState(1);
  const [pages, setPages] = useState([]);
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  useEffect(() => {
    const loadedPages = [];
    for (let i = 1; i <= numPages; i++) {
      loadedPages.push(
        <Page key={i} pageNumber={i} width={1000} /> // You can adjust the width as needed
      );
    }
    setPages(loadedPages);
  }, [numPages]);

  // function goToPage(newPageNumber) {
  //   if (newPageNumber >= 1 && newPageNumber <= numPages) {
  //     setPageNumber(newPageNumber);
  //   }
  // }
  const pdfFile = require('../../assets/Hive-policies-andprocedure.pdf');

  return (
    <div style={{ paddingTop: '90px', height: '100%', overflow: 'auto',
    justifyContent:"center", display:"flex", flexDirection:"column", alignItems:"center",
     }}>
      <Document
        file={pdfFile}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        {pages}
      </Document>
      {/* <div style={{ display: 'flex', justifyContent: 'center', width:"100%" }}>
        {Array.from({ length: numPages }, (_, i) => (
          <button
            key={i}
            onClick={() => goToPage(i + 1)}
            disabled={pageNumber === i + 1}
          >
            Page {i + 1}
          </button>
        ))}
      </div> */}
    </div>
  );
};

export default Policies;
