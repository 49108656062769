import React, {useState, useEffect} from 'react';
// import { Navigation } from 'react-minimal-side-navigation';
import 'react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css';
import bfh from "../../assets/footer/BFH for LM.png";
import bfh_dark from "../../assets/footer/BFH for DM.png";
import blue_icon from "../../assets/footer/blue-icons.svg";
import hive from "../../assets/footer/hive logo 3.svg";
import hive_dark from "../../assets/footer/hive logo HD2 1.svg";
import plus from '../../assets/arts/plus.svg';

import indexText from '../../assets/indexx.ai_black.svg';
import indexText_dark from '../../assets/indexx.ai white.png';

// import arrow from '../../assets/arrow-.svg';
import './Footer.css';
import { baseCEXURL, baseDEXURL, baseURL, baseHiveURL, baseAcadURL } from '../../services/api';

const HiveFooter = () => {

  const [theme, setTheme] = useState(
    localStorage.getItem('selectedTheme') || "dark"
  );

  useEffect(() => {
    const handleStorageChange = (event) => {
      console.log(event);
      setTheme(event.currentTarget.localStorage.selectedTheme);
    };

    window.addEventListener('storage', handleStorageChange);
    
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  return (
    <>
      <footer
        className="site_footer position-relative container-fluid desktop-display"
        style={{marginTop:"250px"}}
     
      >
        {/* {helpIcon && (
          <a
            href={`${baseURL}/indexx-exchange/help`}
            className="need_help"
            style={{
              backgroundImage: `url(${needHelp})`,
              textDecoration: 'none',
            }}
          >
            Need Help?
          </a>
        )} */}

        <div className="flex-align-center d-flex flex-justify-between site_footer_inner row mx-auto" style={{borderBottom:"none"}}>       
          
            <div className=" footercentre col-sm-12 col-md-12 mt-3">
            <div className="col-xs-6 col-md-4 icon_container">
              <img
                src={theme === "dark" ? hive_dark : hive}
                alt="Index flip coin art"
                style={{marginLeft:"30px", width:"63px"}}
              />
              <div className='fw-bold font_30x'>
              Indexx Hive
              </div>
                
          </div>
            <div className="hive-wrapper" style={{width:"fit-content", alignSelf:"end", marginTop:"30px"}}>
              <ul>
                  <li>
                  <a
                      href="https://www.businessforhome.org/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        // className="social-connect-icons"
                        src={theme === "dark" ? bfh_dark : bfh}
                        alt="bfh"
                        style={{width:"134px"}}
                      />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.bbb.org/get-accredited"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        // className="social-connect-icons"
                        src={blue_icon}
                        alt="acc"
                      />
                    </a>
                  </li>
              </ul>
            </div>

           
          </div>

          <div
            className="col-sm-12 col-md-12 footercentre2 text-center mt-5 "
            style={{ marginBottom: 45, minWidth:"65%" }}
          >
            <div className="row text-left">
            <div className="col text-left">
                <span style={{ textAlign: 'left' }}>
                  <p className='fw-bold'>Hive Policies</p>
                  <p className="footer-text text-left">
                    <a
                      href={"https://docs.google.com/document/d/1HtCd14EaqKQ5TNPVCFMjeUPTSgchyPH8hdLE_tPlrls/edit#heading=h.hgs0gsdu1zpw"}
                      style={{ color: '#9F9F9F', textDecoration: 'none' }}
                    >
                      <span className='link_sty'>
                      Captain Bee Rules & Regulations

                      </span>
                    </a>
                    <br />
                    <a
                      href="https://docs.google.com/document/d/1P0wJ3jN8g1w5iYFZwuL-GZeaIg4frjfO55YujMofem8/edit?usp=sharing"
                      style={{ color: '#9F9F9F', textDecoration: 'none' }}
                    >
                      <span className='link_sty'>
                      Privacy Policy

                      </span>
                    </a>
                    <br />
                    <a
                       href={"/policy"}
                      style={{ color: '#9F9F9F', textDecoration: 'none' }}
                    >
                      <span className='link_sty'>
                      Policies & Procedures

                      </span>
                    </a>
                    <br />
                    <br />
                  </p>
                </span>
              </div>
              <div className="col">
                <span style={{ textAlign: 'left' }}>
                  <p className='fw-bold'>Contact Us</p>
                  <p className="footer-text text-left" style={{}}>
                    <a
                      href={`${baseURL}/indexx-exchange/help`}
                      style={{ color: '#9F9F9F', textDecoration: 'none' }}
                    >
                      <span className='link_sty'>
                      Indexx Intro
                      </span>
                    </a>
                    <br />
                    <a
                      href={`${baseURL}/indexx-exchange/help/team`}
                      style={{ color: '#9F9F9F', textDecoration: 'none' }}
                    >
                      <span className='link_sty'>
                      Indexx Team

                      </span>
                    </a>
                    <br />
                    <a
                      href={`${baseURL}/indexx-exchange/help/contact`}
                      style={{ color: '#9F9F9F', textDecoration: 'none' }}
                    >
                      <span className='link_sty'>
                      Indexx Contact Information

                      </span>
                    </a>
                    <br />
                    <br />
                  </p>
                </span>
              </div>
              <div className="col">
                <span style={{ textAlign: 'left' }}>
                  <p className='fw-bold'>About Hive</p>
                  <p
                    className="footer-text text-left"
                    style={{
                      color: '#9F9F9F',
                      textDecoration: 'none',
                      lineHeight: 1.5,
                    }}
                  >
                    <a
                      href={baseAcadURL}
                      style={{ color: '#9F9F9F', textDecoration: 'none' }}
                    >
                      <span className='link_sty'>
                      Hive Academy
                      </span>
                    </a>
                    <br />

                    <a
                      href={`${baseURL}/indexx-exchange/blog`}
                      style={{ color: '#9F9F9F', textDecoration: 'none' }}
                    >
                      <span className='link_sty'>
                      Hive Articles

                      </span>
                    </a>

                    <br />
                    <a
                      href={baseHiveURL}
                      style={{ color: '#9F9F9F', textDecoration: 'none' }}
                    >
                      <span className='link_sty'>
                      Hive Platform

                      </span>
                    </a>

                    <br />
                    <a
                      href={"/coming-soon"}
                      style={{ color: '#9F9F9F', textDecoration: 'none' }}
                    >
                      <span className='link_sty'>
                      Hive Ebook

                      </span>
                    </a>
                    <br />
                    <a
                      href={"https://docs.google.com/presentation/d/133bMxD_tArabCfMWog6gICEi-yxLmmiHXD9lMQUNp0E/edit?usp=sharing"}
                      style={{ color: '#9F9F9F', textDecoration: 'none' }}
                    >
                      <span className='link_sty'>
                      Hive Products Handbook

                      </span>
                    </a>
                    <br />
                    <br />
                    <br />
                  </p>
                </span>
              </div>

            </div>
          </div>
        </div>
      </footer>

      <footer
        className="site_footer position-relative container-fluid mobile-display border-top"
        style={{paddingTop:"50px"}}
      >
        
           

        <div className="col-xs-6 col-md-4 flip_icon_container text-center">
          
           {/* <a href={`${baseURL}/indexx-exchange/help`} className="need_help_phone" style={{ backgroundImage: `url(${needHelp})` ,textDecoration:'none' }}>
                   Need Help?
               </a> */}
            {/* {footerArt === 'flipWoman' ? (
              <img
                src={womanFlipCoin}
                alt="Index flip coin art"
                className="flip_person_icon_mobile flip_woman"
              />
            ) : ( */}
              <img
                src={theme === "dark" ? hive_dark : hive}
                alt="Index flip coin art"
                style={{marginLeft:"30px", width:"63px"}}
                // className="hive_icon_mobile"
              />
            {/* )} */}
          </div>
                  
          

        <div className="flex-align-center d-flex flex-justify-between site_footer_inner row" style={{marginLeft:"15px"}}>
          <div className=" footercentre col-sm-12 col-md-12">
            {/* <a href="/" id="1067941554">
                           <img src="https://lirp.cdn-website.com/5afbaf73/dms3rep/multi/opt/index-38-238w.png"
                               width="50%" height="22%" alt="" />
                       </a>
                        */}
            {/* <h1>
                       <Link to="/" className="primary_color">Get Connected</Link>
                   </h1> */}
                     
                   <div className="hive-wrapper" style={{width:"fit-content", alignSelf:"end", marginTop:"30px"}}>
              <ul>
                  <li>
                  <a
                      href="https://www.businessforhome.org/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={theme === "dark" ? bfh_dark : bfh}
                        alt="bfh"
                        style={{width:"100%"}}
                      />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.bbb.org/get-accredited"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        // className="social-connect-icons"
                        src={blue_icon}
                        alt="acc"
                        style={{width:"100%"}}
                      />
                    </a>
                  </li>
              </ul>
            </div>
          </div>

          <div
            className="col-sm-12 col-md-12 footercentre2 text-left "
            style={{ marginBottom: 40 }}
          >
            <div className="row  w-100 mobile-row border-top">
              <a className="btn footer-drop " data-bs-toggle="collapse" href="#multiCollapseExample1" role="button" aria-expanded="false" aria-controls="multiCollapseExample1">
                Platforms
                <img src={plus} alt="plus" width={"9px"} />
              </a>
              <div className="collapse multi-collapse" id="multiCollapseExample1">
                <div className="">

                <span style={{ textAlign: 'start' }}>
                  <p className="text-extra-small" >
                    <a
                      href={"https://docs.google.com/document/d/1HtCd14EaqKQ5TNPVCFMjeUPTSgchyPH8hdLE_tPlrls/edit#heading=h.hgs0gsdu1zpw"}
                      style={{ color: '#9F9F9F', textDecoration: 'none' }}
                    >
                      <span className='link_sty'>
                      Captain Bee Rules & Regulations

                      </span>
                    </a>
                    <br />
                    <a
                      href="https://docs.google.com/document/d/1P0wJ3jN8g1w5iYFZwuL-GZeaIg4frjfO55YujMofem8/edit?usp=sharing"
                      style={{ color: '#9F9F9F', textDecoration: 'none' }}
                    >
                      <span className='link_sty'>
                      Privacy Policy

                      </span>
                    </a>
                    <br />
                    <a
                       href={"/policy"}
                      style={{ color: '#9F9F9F', textDecoration: 'none' }}
                    >
                      <span className='link_sty'>
                      Policies & Procedures

                      </span>
                    </a>
                    <br />
                    <br />
                  </p>
                </span>
                </div>
              </div>
            </div>

            <div className="row  w-100 mobile-row">
              <a className="btn footer-drop " data-bs-toggle="collapse" href="#multiCollapseExample2" role="button" aria-expanded="false" aria-controls="multiCollapseExample2">
              Contact Us
                <img src={plus} alt="plus" width={"9px"} />
              </a>
              <div className="collapse multi-collapse" id="multiCollapseExample2">
                <div className="">
                <span style={{ textAlign: 'start' }}>
                <p className="text-extra-small">
                    <a
                      href={`${baseURL}/indexx-exchange/help`}
                      style={{ color: '#9F9F9F', textDecoration: 'none' }}
                    >
                      <span className='link_sty'>
                      Indexx Intro
                      </span>
                    </a>
                    <br />
                    <a
                      href={`${baseURL}/indexx-exchange/help/team`}
                      style={{ color: '#9F9F9F', textDecoration: 'none' }}
                    >
                      <span className='link_sty'>
                      Indexx Team

                      </span>
                    </a>
                    <br />
                    <a
                      href={`${baseURL}/indexx-exchange/help/contact`}
                      style={{ color: '#9F9F9F', textDecoration: 'none' }}
                    >
                      <span className='link_sty'>
                      Indexx Contact Information

                      </span>
                    </a>
                    <br />
                    <br />
                  </p>
                </span>
                </div>
              </div>
            </div>

            <div className="row  w-100 mobile-row">
              <a className="btn footer-drop " data-bs-toggle="collapse" data-bs-target="#multiCollapseExample3" role="button" aria-expanded="false" aria-controls="multiCollapseExample3">
              About Hive
                <img src={plus} alt="plus" width={"9px"} />
              </a>
              <div className="collapse multi-collapse" id="multiCollapseExample3">
                <div className="">
                  <span style={{ textAlign: 'start' }}>
                  <p
                    className="footer-text text-left"
                    style={{
                      color: '#9F9F9F',
                      textDecoration: 'none',
                      lineHeight: 1.5,
                    }}
                  >
                    <a
                      href={baseAcadURL}
                      style={{ color: '#9F9F9F', textDecoration: 'none' }}
                    >
                      <span className='link_sty'>
                      Hive Academy
                      </span>
                    </a>
                    <br />

                    <a
                      href={`${baseURL}/indexx-exchange/blog`}
                      style={{ color: '#9F9F9F', textDecoration: 'none' }}
                    >
                      <span className='link_sty'>
                      Hive Articles

                      </span>
                    </a>

                    <br />
                    <a
                      href={baseHiveURL}
                      style={{ color: '#9F9F9F', textDecoration: 'none' }}
                    >
                      <span className='link_sty'>
                      Hive Platform

                      </span>
                    </a>

                    <br />
                    <a
                      href={"/coming-soon"}
                      style={{ color: '#9F9F9F', textDecoration: 'none' }}
                    >
                      <span className='link_sty'>
                      Hive Ebook

                      </span>
                    </a>
                    <br />
                    <a
                      href={"https://docs.google.com/presentation/d/133bMxD_tArabCfMWog6gICEi-yxLmmiHXD9lMQUNp0E/edit?usp=sharing"}
                      style={{ color: '#9F9F9F', textDecoration: 'none' }}
                    >
                      <span className='link_sty'>
                      Hive Products Handbook

                      </span>
                    </a>
                    <br />
                    <br />
                    <br />
                  </p>
                  </span>
                </div>
              </div>
            </div>
          </div>
        
        </div>
      </footer>
    </>
  );
};

export default HiveFooter;
