import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
// Import Swiper modules
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";

const VideoSlider = () => {
  const videos = [
    "https://www.youtube.com/embed/9ULdWShBz3k?enablejsapi=1",
    "https://www.youtube.com/embed/TQ-rLpvgFso?enablejsapi=1",
    "https://www.youtube.com/embed/rw1fgBWrygo?si=tlPSM1qyCH744WXl",
    "https://www.youtube.com/embed/u_licuXZO54?enablejsapi=1",
    "https://www.youtube.com/embed/uctK2OEhGT0?enablejsapi=1",
  ];

  const iframesRef = useRef([]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  const handleSlideChange = () => {
    iframesRef.current.forEach((iframe) => {
      if (iframe && iframe.contentWindow) {
        iframe.contentWindow.postMessage(
          '{"event":"command","func":"pauseVideo","args":""}',
          "*"
        );
      }
    });
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    iframesRef.current.forEach((iframe) => {
      iframe.onload = () => {
        iframe.contentWindow.postMessage(
          '{"event":"command","func":"pauseVideo","args":""}',
          "*"
        );
      };
    });

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div
      style={{
        position: "relative",
        maxWidth: "1280px",
        margin: "0 auto",
        width: "90%",
      }}
    >
      <Swiper
        spaceBetween={50}
        slidesPerView={1}
        navigation={{
          prevEl: ".custom-prev",
          nextEl: ".custom-next",
        }}
        onSlideChange={handleSlideChange}
        modules={[Navigation, Pagination, Scrollbar, A11y]}
        style={{
          height: windowWidth < 769 ? "300px" : "700px",
          padding: "10px",
        }} // Adjust iframe height based on window width
      >
        {videos.map((video, index) => (
          <SwiperSlide key={index}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <iframe
                ref={(el) => (iframesRef.current[index] = el)}
                width="100%"
                height="100%"
                src={video}
                title={`YouTube video ${index + 1}`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>

      {/* Custom Previous and Next Buttons */}
      <button
        className="custom-prev"
        style={{
          ...styles.navButton,
          ...styles.prevButton,
          left:
            windowWidth < 769
              ? windowWidth < 480
                ? "-20px"
                : "-30px"
              : "-60px", // Adjust position based on window width
        }}
      >
        <ArrowBackIosIcon sx={{ "&:hover": { color: "#777" } }} />
      </button>
      <button
        className="custom-next"
        style={{
          ...styles.navButton,
          ...styles.nextButton,
          right:
            windowWidth < 769
              ? windowWidth < 480
                ? "-20px"
                : "-30px"
              : "-60px", // Adjust position based on window width
        }}
      >
        <ArrowForwardIosIcon sx={{ "&:hover": { color: "#777" } }} />
      </button>
    </div>
  );
};

const styles = {
  navButton: {
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    backgroundColor: "transparent",
    border: "none",
    cursor: "pointer",
    zIndex: 10,
    fontSize: "24px",
    display: "flex",
    alignItems: "center",
  },
  prevButton: {
    left: "-60px", // Default position for desktop
  },
  nextButton: {
    right: "-60px", // Default position for desktop
  },
};

export default VideoSlider;
